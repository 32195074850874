import Image from 'next/image';
import dynamic from 'next/dynamic';
import { useState } from 'react';

const FeatureAppHeader = dynamic(
  () => import('@containers/home/components/feature-app/feature-app-header')
);
const FeatureAppItems = dynamic(
  () => import('@containers/home/components/feature-app/feature-app-items')
);

import { featureApps, IFeatureApp } from '@containers/home/components/feature-app/_data';

const FeatureApp = () => {
  const [selected, setSelected] = useState<IFeatureApp>(featureApps[0]);

  return (
    <section className="feature-app">
      <FeatureAppHeader />

      <div className="feature-app--content">
        <div className="feature-app--content-main">
          <div className="feature-app--content-decoration">
            <Image
              src="/images/icons/dot.png"
              alt="dot"
              width={90}
              height={90}
              objectFit="contain"
              objectPosition="center"
            />
          </div>

          <FeatureAppItems selected={selected} setSelected={setSelected} />
        </div>

        <div className="feature-app--content-image">
          <div className="feature-app--content-image-source">
            <Image
              src={selected.image}
              alt={selected.title}
              width={450}
              height={400}
              layout="responsive"
              objectFit="contain"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureApp;
