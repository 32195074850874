export interface IFeatureApp {
  id: number;
  title: string;
  description: string;
  image: string;
  icon: string;
}

export const featureApps: IFeatureApp[] = [
  {
    id: 1,
    title: 'Free Registration',
    description: 'it’s fast and free to join. Relax, your information are safe.',
    image: '/images/illustrations/explore_brand.webp',
    icon: '/images/illustrations/credit_card_plus.webp'
  },
  {
    id: 2,
    title: 'Collect point',
    description: 'The more you shop, the more you earn reward  points.',
    image: '/images/illustrations/collect_point.webp',
    icon: '/images/illustrations/monthly_salary.webp'
  },
  {
    id: 3,
    title: 'Reedem special gift',
    description:
      'Chakra Rewards has exciting options to redeem your earned reward points for e-vouchers, mobile top up and much more.',
    image: '/images/illustrations/redeem_special_gift.webp',
    icon: '/images/illustrations/gift.webp'
  }
];
